import {
    ApolloClient,
    ApolloQueryResult,
    gql,
    InMemoryCache,
    createHttpLink,
    useQuery
  } from "@apollo/client";

  import {WEBSERVER, APISERVER} from "../services/globals"

  export class Queries {
    private _client;



    constructor() {
       const link = createHttpLink({
        uri: APISERVER,
        credentials: 'include'
      });

      this._client = new ApolloClient({
        cache: new InMemoryCache(),
        link
      });
    };


  public getProductDetailBySlug = async (
        slug: string
      ): Promise<ApolloQueryResult<any>> => {
        const response = await this._client.query({
          query: this.PRODUCT_DETAIL_BY_SLUG,
          variables: { slug },
        });
        return response;
  };
  
  public getProductBySlugs = async (): Promise<ApolloQueryResult<any>> => {
    const response = await this._client.query({
      query: this.GET_PRODUCT_BY_SLUG,
    });
    return response;
};

public getItemById = async (
    id: string
  ): Promise<ApolloQueryResult<any>> => {
    const response = await this._client.query({
      query: this.GET_ITEM_BY_ID,
      variables: { id },
    });
    return response;
};

public getItemsFromPage = async (
  slug: string
): Promise<ApolloQueryResult<any>> => {
  const response = await this._client.query({
    query: this.GET_ITEMS_FROM_PAGE,
    variables: { slug },
  });
  return response;
};

public getItemsFromPage2 = async (
  slug: string
): Promise<ApolloQueryResult<any>> => {
  const response = await this._client.query({
    query: this.GET_ITEMS_FROM_PAGE2,
    variables: {
      input : {
        "collectionSlug" :  slug,
        "groupByProduct" : true
      }
    }
  });
  return response;
};


public getCategories = async (): Promise<ApolloQueryResult<any>> => {
    const response = await this._client.query({
      query: this.GET_CATEGORIES
    });
    return response.data;
};

public placeCart = async (productId: string, qty : number): Promise<ApolloQueryResult<any>> => {
  const response = await this._client.mutate({
    mutation: this.PLACE_CART,
    variables: {productId,qty}
  });
  return response.data;
};

public changeCartLineMutation = async (Id: string, qty : number): Promise<ApolloQueryResult<any>> => {
  const response = await this._client.mutate({
    mutation: this.CHANGE_CART_LINE,
    variables: {Id,qty}
  });
  return response.data;
};

public getMe () {
  const { loading, error, data } = useQuery(this.GET_ME);
  if (loading) {
    return 0;
  }
  if (error) {
    console.error(error);
    return 0;
  }
  return data;
}


public SearchProducts = async (
  term: string,
  skip: number,
  take: number
): Promise<ApolloQueryResult<any>> => {
  const response = await this._client.query({
    query: this.SEARCH_PRODUCTS,
    variables: { term, skip, take },
  });
  return response;
};


public getCartCount = async (): Promise<number> => {
  const response = await this._client.query({
    query: this.CART_COUNT
  });
  //console.log("++++1 getCartCount status: "  + JSON.stringify(response));
  if (response.data.activeOrder != null ) {
    return response.data.activeOrder.lines.length;
  }
};


public getCartFull () {
  const { loading, error, data } = useQuery(this.CART_COUNT);
  //console.log("++++ getCartCount status: " + loading +"  "+ JSON.stringify(data));

  if (loading) {
    return 0;
  }
  if (error) {
    console.error(error);
    return 0;
  }
  //console.log("++++ getCartCount response: " + JSON.stringify(data));
  return data.activeOrder;
};

public getActiveCustomer () {
  const { loading, error, data } = useQuery(this.ACTIVE_CUSTOMER);
  console.log("++++ getActiveCustomer status: " + loading +"  "+ JSON.stringify(data));

  if (loading) {
    return 0;
  }
  if (error) {
    console.error(error);
    return 0;
  }
  //console.log("++++ getCartCount response: " + JSON.stringify(data));
  return data.activeCustomer;
};

public getPaymentMethods () {
  const { loading, error, data } = useQuery(this.AVAILIABLE_PAYMENT_METHODS);
  return data;
}

public getShippingMethods () {
  const { loading, error, data } = useQuery(this.ELIGIBLE_SHIPPING_METHODS);
  return data;
}

public getShippingMethodSelected () {
  const { loading, error, data } = useQuery(this.GET_SHIPPING_METHOD_SELECTED);
  return data;
}


public sendFormData = async (
  to: string,
  token: string,
  data: string //as json
): Promise<ApolloQueryResult<any>> => {
  const response = await this._client.query({
    query: this.SEND_FORM_DATA,
    variables: { to, token, data },
  });
  //alert(JSON.stringify(response));
  return JSON.parse(response.data.formtomail).success;
};



private GET_ITEM_BY_ID  = gql `
  query ($id: ID!) {
    product (id: $id) {
      id
    	name
    	description
  }
}
`

private  CHANGE_CART_LINE  = gql`
 mutation ($id: ID! , $qty: Int!) {
  adjustOrderLine(orderLineId: $id, quantity: $qty) {
    ... on Order {
      id
      code
      state
      subTotal
      subTotalWithTax
    }
    ... on OrderLimitError {
      errorCode
      message
      maxItems
    }
  }
}`;


private  GET_SHIPPING_METHOD_SELECTED = gql`
  query {
    activeOrder {
      id
      code
      state
      customer {
      id
      title
      firstName
      lastName
      emailAddress
      } 
      shippingAddress {
        fullName
        company
        streetLine1
        streetLine2
        city
        postalCode
        countryCode
        phoneNumber
      }
      lines {
        id
        unitPrice
        unitPriceWithTax
        linePrice
        linePriceWithTax
        quantity
        productVariant {
          id
          sku
          name
          price
          priceWithTax
          product {
            name
            slug
          }
        }
      }
      discounts {
        type
        description
        amount
        amountWithTax
      }
      shippingLines {
         shippingMethod {
           id 
           name
           description
           code
         } 
      }
      shipping
      shippingWithTax
      total
      totalWithTax
    }
  }
`;




private  CART_COUNT = gql`
  query {
    activeOrder {
      id
      code
      state
      lines {
        id
        unitPrice
        unitPriceWithTax
        linePrice
        linePriceWithTax
        quantity
        productVariant {
          id
          sku
          name
          price
          priceWithTax
          product {
            name
            slug
          }
        }
      }
      total
      totalWithTax
    }
  }
`;


  private  PLACE_CART = gql` 
  mutation ($productId: ID!, $qty: Int!) {
    addItemToOrder(productVariantId: $productId, quantity: $qty) {
      ... on Order {
        id
        code
        state
        subTotal
        subTotalWithTax
      }
      ... on OrderLimitError {
        errorCode
        message
        maxItems
      }
    }
  } `;

  private  GET_ITEMS_FROM_PAGE = gql` 
        query ($slug: String!) {
            collection(slug: $slug) {
              id
              slug
              name
              parent {
                slug
                name
              }
              productVariants {
                  items {
                      priceWithTax
                      id
                      product {
                          createdAt
                          id
                          slug
                          name
                          description
                          assets {type source name}
                          facetValues {id name code}
                          variants {
                            id
                            name
                            price
                            priceWithTax
                            assets {type source name}
                          }
                       }
                    }
                  }
              }
          } `;


private  GET_ITEMS_FROM_PAGE2 = gql` 
  query SearchProducts($input: SearchInput!) {
  search(input: $input) {
    totalItems
    items {
      productName
      slug
      description
      productAsset {
        id
        preview
      }
      price {
        ... on SinglePrice {
         value
       }
       ... on PriceRange {
         min
         max
        }
     }   
      priceWithTax {
        ... on SinglePrice {
          value
        }
        ... on PriceRange {
          min
          max
        }
      }
      currencyCode
    }
  }
}
`;

  

private GET_PRODUCT_BY_SLUG = gql`
query {
  products {
    items {
      id
      slug
    }
  }
}
`;


        private GET_CATEGORIES = gql`
   query  {
    collections {
      items {
        id
        name
        slug
        parent {
          id
          name
          slug
            }
        children {id,name,slug}
        }
      }
  }
  `;     

  private PRODUCT_DETAIL_BY_SLUG = gql`
  query ($slug: String!) {
    product(slug: $slug) {
      id
      name
      createdAt
      updatedAt
      name
      description
      assets {
        source
      }
      facetValues {
        name
        code
        id
        facet {
          name
        }
      }
      variantList {
        totalItems
      }
      variants {
        sku
        name
        productId
        price
        priceWithTax
        id
      }
      collections {
        name
        slug
        description
        
      }
    }
  }
`;

private GET_ME = gql`
query {
  me {
      id
  }
}
`;

private SEARCH_PRODUCTS = gql`
  query SearchProducts($term: String!, $skip: Int, $take: Int) {
  search(
    input: {
      term: $term,
      groupByProduct: true,
      skip: $skip,
      take: $take }
  ) {
    totalItems
    facetValues {
      count
      facetValue {
        id
        name
        facet {
          id
          name
        }
      }
    }
    items {
      productName
      slug
      productAsset {
        id
        preview
      }
      priceWithTax {
        ... on SinglePrice {
          value
        }
        ... on PriceRange {
          min
          max
        }
      }
      currencyCode
    }
  }
}`;


private ACTIVE_CUSTOMER = gql`
query {
  activeCustomer {
      id
      title
      firstName
      lastName
      emailAddress
      addresses {
        id
        fullName
        company
        streetLine1
        streetLine2
        city
        postalCode
        phoneNumber
        defaultShippingAddress
        defaultBillingAddress
        country {
          id
          code
          name
        }
      }
  }
}
`;


private  AVAILIABLE_PAYMENT_METHODS = gql`
  query {
    eligiblePaymentMethods {
      id
      name
      code
      description
      isEligible
    }
  }
`;


private  ELIGIBLE_SHIPPING_METHODS = gql`
  query {
    eligibleShippingMethods {
      id
      code
      name
      price
      priceWithTax
    }
  }
`;



private SEND_FORM_DATA = gql`
query ($to :String!, $token : String!, $data : String) {
   formtomail(
      to: $to,
      token : $token,
      data: $data
    )   
}
`;

}
