import {Queries} from  "../services/queries";
import {USER_LOGIN_MUTATION} from "../queries/user-login-mutation";
import {USER_LOGOUT_MUTATION} from "../queries/user-logout-mutation";
import { ApolloClient, InMemoryCache, gql, useMutation } from "@apollo/client";

import {WEBSERVER} from "../services/globals"

import { Formik, Field, Form, FormikHelpers  } from "formik";
import {ActiveCustomer} from "./contracts/types";


interface Values {
    username: string;
    password: string;
  }

const registerurl:string = WEBSERVER + "/register";
const pwlosturl:string = WEBSERVER + "/account/pw-lost-email";
const acc_addressurl: string = WEBSERVER + "/acc-address";

export let activeCustomer:ActiveCustomer;

const User = () => {
    const queries = new Queries();
 //   const user = queries.getMe();
    activeCustomer = queries.getActiveCustomer();
    console.log("User: activeCustomer:" + JSON.stringify(activeCustomer));
    let userid:number = 0;
    let username:string = "";  
    if (activeCustomer !== null) {
      userid = activeCustomer.id;
      username = activeCustomer.firstName + " " + activeCustomer.lastName;
    }
    const [login, { error: cError, loading: cLoading }] = useMutation(
        USER_LOGIN_MUTATION
      );

    const [logout, { error: oError, loading: oLoading }] = useMutation(
        USER_LOGOUT_MUTATION
      );


    const doLogout = async () => {
        const resp = await logout();
        _logout();
    }


    function _logout() {
            //alert("logout");
            window.location.href= WEBSERVER; //Startseite
    }

    const formInitValues: Values = {
        username: "",
        password: "",
    };
    
      function onSuccess() {
//        alert("successs");
        window.location.reload();
    }
      const handleSubmit = async (
        values: Values,
        { setSubmitting, resetForm }: FormikHelpers<Values>
      ) => {
        setSubmitting(false);
        const resp = await login({ variables: values });
        if (!resp.errors) {
          resetForm();
          onSuccess();
        }
      }

    return (
        <div className="user_login_logout">
            <div className="dropdown">
            <a className="dropdown-toggle"  id="dropdownMenuButton1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="bi-person"></i>
            </a>
            <ul className="dropdown-menu p-4" aria-labelledby="dropdownMenuButton1">
        { (!userid) ? 
        <Formik initialValues={formInitValues} onSubmit={handleSubmit}>
            <Form>
            <div className="mb-3">    
            <label htmlFor="Email1" className="form-label">E-Mail</label>   
            <Field className="form-control" id="Email1" name="username" type="text" />
            </div>
            <div className="mb-3">    
            <label htmlFor="Password1" className="form-label">Passwort</label>   
            <Field className="form-control" id="Password1" name="password" type="password" />
            </div>
            <button className="btn btn-primary" type="submit">Anmelden</button>
            <h2></h2>
                    <div className="p-2"><a className="btn btn-sm btn-outline-primary" href={pwlosturl}>Passwort vergessen</a></div>
                    <div className="p-2"><a className="btn btn-sm btn-outline-primary" href={registerurl}>Neu Registrieren</a></div>
            
            </Form>
        </Formik>
     : <div><p> Hallo {username} </p>
     <p><a className="btn btn-info" href={acc_addressurl}>Kundenbereich</a></p>
     <button className="btn btn-primary" onClick={doLogout}>Logout</button></div>
        }
        </ul>
        </div>  
    </div>        
    );
};

export default User;